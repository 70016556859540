<div class="form-wrapper">
    <div class="form-container" *ngIf="!showReview">

        <div class="form-group" *ngIf="!isSubmissionComplete && !showReview">

            <h4>Upload Shipment File</h4>

            <upsc-s3-file-downloader class="download-template" [bucketName]="'members-xlsx.upsc'"
                [buttonClass]="'transparent-button'" [buttonText]="'Download Template'"
                [fileName]="'ReportShipmentsBulk_Template.xlsx'"></upsc-s3-file-downloader>

        </div>

        <span *ngIf="!showReview" class="page-description">To upload shipments you would like coverage for, use the Bulk
            Upload template.
            Review
            and confirm the accuracy of the shipment details in 3 easy steps.
        </span>
    </div>

    <section class="upload-area" (click)="handleClick($event)" (drop)="handleDrop($event)"
        (dragover)="allowDrop($event)" *ngIf="!isSubmissionComplete && !showReview">
        <div class="upload-box" *ngIf="!selectedFileName && !editingExistingFile">
            <img class="drive-folder-icon" src="../../assets/icons/Frame 22982.svg" alt="drive-folder-icon">
            <div class="unselected-file-upload-text">
                <p>
                    <a>Click to upload</a> or drag and drop your file here
                </p>
                <p class="file-types"> CSV, XLS, OR XLSX </p>
                <p class="file-size">Maximum upload file size: 4 MB</p>
            </div>
        </div>

        <div class="selected-file-container">
            <input #fileInput type="file" (change)="onFileInputChange($event)" [disabled]="isFileInputDisabled"
                style="display: none;"
                accept=".csv, .xls, .xlsx, /vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
            <div class="upload-box" *ngIf="selectedFileName && !editingExistingFile">

                <div class="file-wrapper">
                    <img class="trashcan" src="../../assets/icons/trashcan.svg" alt="trashcan-icon"
                                        (click)="deleteFile()" height="13" width="12">
                    <img class="excel-4954" src="../../assets/icons/excel-4954 (1) 1.svg" alt="excel-icon">
                    <p>
                        <span> {{ selectedFileName }} </span>
                        <span *ngIf="!errorMessage"> is now ready to review </span>
                    </p>
                </div>

                <div class="error-file-upload" *ngIf="errorMessage">
                    <img class="error-icon" src="../../assets/icons/Error_16x16.svg" alt="error-icon">
                    <mat-error class="error-message"> {{ errorMessage }} </mat-error>
                </div>

                <div class="duplicate-addresses" *ngIf="duplicateAddressMessage">
                    {{ duplicateAddressMessage }}
                </div>

                <div class="btn-container">
                    <button mat-stroked-button color="primary" (click)="replaceFile()">
                        Replace </button>
                    <button mat-flat-button color="primary" style="gap: 10px;"
                        [ngClass]="{'zero-scores-remaining-submit-btn': isZeroRemainingScores}"
                        (click)="showSubmissionComplete($event)">
                        {{submitBtnMessage }} </button>
                </div>
            </div>
        </div>



    </section>
    <section *ngIf="!showReview">
        <upsc-report-shipments-recent-uploads [fileUploads]="fileUploads"></upsc-report-shipments-recent-uploads>
    </section>
    <section *ngIf="showReview">
        <upsc-report-shipments-review-errors [shipmentReportsReviewsData]="dataFromFile"
            [recentUploadData]="currerntFile" [fileId]="currentFileId"></upsc-report-shipments-review-errors>
    </section>
    <section class="instructions-container" *ngIf = "showInstructions">
        <upsc-s3-file-downloader class="download-instructions" [bucketName]="'members-xlsx.upsc'"
            [buttonClass]="'transparent-button'" [buttonText]="'Bulk Upload Instructions'"
            [fileName]="'ReportShipmentsBulk_Template.xlsx'" [showDownloadIcon]="true"></upsc-s3-file-downloader>
        <upsc-s3-file-downloader class="download-instructions" [bucketName]="'members-docx.upsc'"
            [buttonClass]="'transparent-button'" [buttonText]="'Bulk Upload FAQ'" [fileName]="'Bulk_Upload_FAQs.docx'"
            [showDownloadIcon]="true"></upsc-s3-file-downloader>
        <button mat-raised-button [ngClass]="buttonClass" (click)="toggleTipsAndTricks()"
            class="download-instructions">View Tips&Tricks</button>
    </section>

    <section *ngIf = "showInstructions">
        <mat-expansion-panel #tipsAndTricksPanel hideToggle>

            <mat-expansion-panel-header *ngIf="this.tipsAndTricksPanel.expanded" style="pointer-events: none;">
                <button mat-icon-button class="close-button" (click)="closePanel(); $event.stopPropagation();"
                    style="pointer-events: auto;">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-expansion-panel-header>

            <!-- First Section -->
            <div class="info-section">
                <mat-icon>
                    <img src="../../assets/images/fact_check.svg" alt="fact-check">
                </mat-icon>
                <h3>7 Required Columns</h3>
                <ul>
                    <li>1. Carrier</li>
                    <li>2. Ship Date</li>
                    <li>3. Service Level</li>
                    <li>4. Tracking Number</li>
                    <li>5. Insured Value</li>
                    <li>6. From Country</li>
                    <li>7. To Country</li>
                </ul>
            </div>

            <mat-divider [vertical]="true"></mat-divider>

            <!-- Second Section -->
            <div class="info-section">
                <mat-icon>
                    <img src="../../assets/images/insured_value.svg" alt="attach-money">
                </mat-icon>
                <h3>Insured Value</h3>
                <p>
                    The insured value cannot be
                    negative, and your service level can
                    not exceed your Parcel Pro pre-
                    approved limits.
                </p>
            </div>

            <mat-divider [vertical]="true"></mat-divider>

            <!-- Third Section -->
            <div class="info-section">
                <mat-icon>
                    <img src="../../assets/images/edit_note.svg" alt="edit-note">
                </mat-icon>
                <h3>Required Fields Cannot Be Blank</h3>
                <p>The fields in the required columns
                    MUST not be blank.</p>
            </div>

            <mat-divider [vertical]="true"></mat-divider>

            <!-- Fourth Section -->
            <div class="info-section">
                <mat-icon>
                    <img src="../../assets/images/alarm.svg" alt="alarm">
                </mat-icon>
                <h3>12 Hours to Upload</h3>
                <p>
                    You have 12 hours after uploading
                    your shipment file to submit your
                    request for insurance before the
                    batch expires.
                </p>
            </div>
        </mat-expansion-panel>
    </section>

    <upsc-element-blocker *ngIf="isDataLoadingFromFile"></upsc-element-blocker>
</div>