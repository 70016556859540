<div>
    <ng-container [ngTemplateOutlet]="form"></ng-container>
</div>

<ng-template #form>
    
    <form [formGroup]="formGroup" class="report-shipments-single-upload" (ngSubmit)="onFormSubmit($event, formGroup.value)">
        
        {{ 'pleaseNote' | translate}}
        <strong>{{ 'reportShipment.notification1' | translate }}</strong><br>{{ 'reportShipment.notification2' | translate }}
        
        <div class = "form-wrapper">
            <div class="form-container general-info" [formGroup]="formGroup.controls.generalInformation">
                <h4 class="section-title">General Infromation</h4>

                <mat-form-field class="form-group">
                    <mat-label>{{'Carrier' | translate}}</mat-label>
                    <mat-select required formControlName="carrier">
                        <mat-option value="" disabled selected>{{'reportShipment.selectCarrier'|translate}}</mat-option>
                        <mat-option *ngFor="let carrier of carriers" [value]="carrier.CarrierCode">
                            {{ carrier.CarrierCode }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formGroup.controls.generalInformation?.controls.carrier?.touched && formGroup.controls.generalInformation?.controls.carrier?.invalid">
                    <span *ngIf="formGroup.controls.generalInformation?.controls.carrier?.errors?.required">
                        <span>{{'reportShipment.carrierRequired'|translate}}</span>
                    </span>
                    </mat-error>
                </mat-form-field>
                <upsc-element-blocker *ngIf="isCarriersLoading"></upsc-element-blocker>

                <mat-form-field class = "form-group">
                    <mat-label>{{'ServiceType'|translate}}</mat-label>
                    <mat-select required formControlName="serviceType">
                        <mat-option value="" selected>{{'reportShipment.selectServiceType'|translate}}</mat-option>
                        <mat-option *ngFor="let service of filteredServices" [value]="service.ServiceCode">
                            {{ service.Description || service.ServiceCode }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formGroup.controls.generalInformation?.controls.serviceType?.touched && formGroup.controls.generalInformation?.controls.serviceType?.invalid">
                        <span *ngIf="formGroup.controls.generalInformation?.controls.serviceType?.errors?.required">
                            <span>{{'ServiceTypeRequired'|translate}}</span>
                        </span>
                    </mat-error>
                </mat-form-field>

                <mat-form-field class = "form-group">
                    <mat-label>Coverage</mat-label>
                    <input required matInput formControlName="coverage">
                    <mat-error *ngIf="formGroup.controls.generalInformation?.controls.coverage.touched && formGroup.controls.generalInformation?.controls.coverage.invalid">
                        <span *ngIf="formGroup.get('generalInformation.coverage').errors.required">
                            <span>Coverage amount is required</span>
                        </span>
                    </mat-error>
                </mat-form-field>

                <mat-form-field class = "form-group">
                    <mat-label>{{'TrackingNumber'|translate}}</mat-label>
                    <input required
                            matInput
                            #trackingNumber
                            formControlName="trackingNumber">
                    <mat-error *ngIf="formGroup.controls.generalInformation?.controls.trackingNumber?.errors">
                        <span *ngIf="formGroup.get('generalInformation.trackingNumber').errors.required">
                            {{ 'trackingnumberrequired' | translate }}
                          </span>
                          <span *ngIf="formGroup.get('generalInformation.trackingNumber').errors.existed">
                            {{ 'trackingNumberReported' | translate }}
                          </span>
                          <span *ngIf="formGroup.get('generalInformation.trackingNumber').errors.serverError">
                            {{ 'serverError' | translate }}
                          </span>
                    </mat-error>
                    <upsc-element-blocker *ngIf="isTrackingNumberValidating"></upsc-element-blocker>
                </mat-form-field>

                
                <mat-form-field class = "form-group">
                    <mat-label>{{'ShipmentType' | translate}}</mat-label>
                    <mat-select required formControlName="shipmentType">
                        <mat-option value="" disabled selected>{{'reportShipment.selectShipmentType' | translate}}</mat-option>
                        <mat-option *ngFor="let shipmentType of shipmentTypes" [value]="shipmentType.shipmentTypeCode">
                            {{ shipmentType.shipmentTypeName | translate }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formGroup.controls.generalInformation?.controls.shipmentType?.touched && formGroup.controls.generalInformation?.controls.shipmentType?.invalid">
                        <span *ngIf="formGroup.controls.generalInformation?.controls.shipmentType?.errors?.required">
                            <span>{{'reportShipment.shipmentTypeRequired'|translate}}</span>
                        </span>
                    </mat-error>
                </mat-form-field>

                <div class="inner-container">
                    <mat-form-field class="form-group">
                        <mat-label>Ship From Location</mat-label>
                        <mat-select required formControlName="shipFromLocation">
                            <mat-option value="" disabled selected>{{'reportShipment.selectShipFromlocation' | translate}}</mat-option>
                            <mat-option *ngFor="let country of countries" [value]="country.CountryCode">
                                {{ country.CountryName }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup.controls.generalInformation?.controls.shipFromLocation?.touched && formGroup.controls.generalInformation?.controls.shipFromLocation?.invalid">
                            <span *ngIf="formGroup.controls.generalInformation?.controls.shipFromLocation?.errors?.required">
                                <span>Ship From location is required</span>
                            </span>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="form-group"> 
                        <mat-label>Ship To Location</mat-label>
                        <mat-select required formControlName="shipToLocation">
                            <mat-option value="" disabled selected>Select Shipt To location </mat-option>
                            <mat-option *ngFor="let country of countries" [value]="country.CountryCode">
                                {{ country.CountryName }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup.controls.generalInformation?.controls.shipToLocation?.touched && formGroup.controls.generalInformation?.controls.shipToLocation.invalid">
                            <span *ngIf="formGroup.controls.generalInformation?.controls.shipToLocation?.errors?.required">
                                <span>Ship To location is required</span>
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>

            </div>

            <div class="form-container shipping-info" [formGroup] = "formGroup.controls.shippingInformation">
                <h4 class="section-title">Shipping Information</h4>
                <div class="inner-container">

                    <mat-form-field class="form-group">
                        <mat-label>Company Name</mat-label>
                            <input matInput
                                formControlName = "companyName">
                    </mat-form-field>

                    <mat-form-field class="form-group">
                        <mat-label>Phone Number</mat-label>
                        <input matInput
                            formControlName = "phoneNumber">
                    </mat-form-field>

                </div>

                <div class="inner-container">
                    <mat-form-field class="form-group">
                        <mat-label>First Name</mat-label>
                        <input required matInput
                            formControlName = "firstName">
                        <mat-error *ngIf="formGroup.controls.shippingInformation?.controls.firstName.touched && formGroup.controls.shippingInformation?.controls.firstName.invalid">
                            <span *ngIf="formGroup.controls.shippingInformation?.controls.firstName.errors?.required">
                                <span>First name is required</span>
                            </span>
                        </mat-error>
                    </mat-form-field>
                    
                    <mat-form-field class="form-group">
                        <mat-label>Last Name</mat-label>
                        <input required matInput
                            formControlName = "lastName">
                        <mat-error *ngIf="formGroup.controls.shippingInformation?.controls.lastName.touched && formGroup.controls.shippingInformation?.controls.lastName.invalid">
                            <span *ngIf="formGroup.controls.shippingInformation?.controls.lastName.errors?.required">
                                <span>Last name is required</span>
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>

                <mat-form-field class="form-group">
                    <mat-label>Ship To Zip Code</mat-label>
                    <input required matInput
                        formControlName = "shipToZipCode">
                    <mat-error *ngIf = "formGroup.controls.shippingInformation?.controls.shipToZipCode.touched && formGroup.controls.shippingInformation?.controls.shipToZipCode.invalid">
                        <span>Invalid Zip Code</span>
                    </mat-error>
                </mat-form-field>

                <div class="inner-container">

                    <mat-form-field class="form-group">
                        <mat-label>Province</mat-label>
                        <input matInput
                            formControlName = "province">
                    </mat-form-field>
                    
                    <mat-form-field class="form-group">
                        <mat-label>City</mat-label>
                        <input matInput required
                            formControlName = "city">
                        <mat-error *ngIf="formGroup.controls.shippingInformation?.controls.city?.touched && formGroup.controls.shippingInformation?.controls.city.invalid">
                            <span *ngIf="formGroup.controls.shippingInformation?.controls.city?.errors?.required">
                                <span>Ship To City is required</span>
                            </span>
                        </mat-error>
                    </mat-form-field>
                    

                </div>

                <div class = "inner-container">
                    <mat-form-field class="form-group">
                        <mat-label>Weight</mat-label>
                        <input matInput
                            formControlName = "weight">
                    </mat-form-field>
                    
                    <mat-form-field class="form-group">
                        <mat-label>Reference Number</mat-label>
                        <input matInput
                            formControlName = "referenceNumber">
                    </mat-form-field>

                </div>
            
            </div>
        </div>

        <div class="form-checkbox-container" *ngIf="!isEditingExistingData">
            
            <mat-checkbox class="checkbox" formControlName = "isAgreeToTerms">
                I have read, understand, agree, and full compiled with ParcelPro’s <a href="javascript:;" (click)="openTermsAndConditionsDialog($event)" > {{'reportShipment.terms2'|translate}} </a>
            </mat-checkbox>
        
            <button type="submit" mat-raised-button [disabled]="!isFormValid()">Submit</button>
        
        </div>
            
        <div class="question-container" *ngIf="!isEditingExistingData">
            <h4>Having Questions?</h4>
            <upsc-s3-file-downloader  [bucketName]="'members-xlsx.upsc'"
             [buttonText]="'Single Report Upload FAQ'"
            [fileName]="'Single_Report_Upload_FAQ.docx'" [showDownloadIcon]="true"></upsc-s3-file-downloader>
            <hr>
            <div class="footer">
                <!-- Footer content goes here -->
                <span>If you need to make any changes to your shipment upload, please contact us immediately. Customer Service Department by email 
                    <a href="mailto:customerservice&#64;parcelpro.com">customerservice&#64;parcelpro.com</a> or by phone <a> 888-683-2300 </a>.
                </span>
            </div>
        </div>
    </form>
</ng-template>
