import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { Component, Input, input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatOption, MatSelect } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { AppState } from 'app/app.state';
import { Carriers } from 'app/shared/enum/general-enum';
import { ICountry } from 'app/shared/models/country.interface';
import { ICustomerCarrier } from 'app/shared/models/customer-carrier.interface';
import { Customer } from 'app/shared/models/customer/customer.model';
import { ElementBlockerModule } from '../shared/components/element-blocker/element-blocker.module';
import { User } from '../shared/services/user/models/user.model';
import { LookupService } from 'app/shared/services/lookup/lookup.service';
import { UtilityService } from 'app/shared/services/utility/utility.service';
import { ShipmentService } from 'app/ship/services/shipment.service';
import * as _ from 'lodash';
import { IReportShipmentEU } from 'app/history/report-history/report-shipment-eu-dialog/models/report-shipment-eu.interface';
import { UserService } from 'app/shared/services/user/user.service';
import { catchError, map, Observable, of, Subscription } from 'rxjs';
import { IReportService } from 'app/history/models/report-service.interface';
import { NotificationService } from 'app/shared/services/notification/notification.service';
import { ValidationService } from 'app/shared/services/validation/validation.service';
import { ReportShipmentService } from 'app/history/services/report-shipment.service';
import { ErrorHandlerService } from 'app/shared/services/error-handler/error-handler.service';
import { UserTermsConditionsComponent } from 'app/account/user-settings/user-terms-conditions/user-terms-conditions.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { NotificationType } from 'app/shared/models/notification-type';
import { MatButton } from '@angular/material/button';
import { S3FileDownloaderModule } from '../shared/components/s3-file-downloader/s3-file-downloader.module';

@Component({
  selector: 'upsc-report-shipments-single-upload',
  templateUrl: './report-shipments-single-upload.component.html',
  styleUrls: ['./report-shipments-single-upload.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NgTemplateOutlet,
    ReactiveFormsModule,
    TranslateModule,
    MatFormField,
    MatSelect,
    MatLabel,
    MatError,
    MatOption,
    ElementBlockerModule,
    MatInput,
    MatError,
    MatCheckbox,
    MatButton,
    S3FileDownloaderModule,
  ],
})
export class ReportShipmentsSingleUploadComponent implements OnInit {
  public formGroup: UntypedFormGroup;
  public user: User;
  public customer: Customer;
  public userCurrency = 'USD';
  public maxInsuredValue = 0;
  public isPostalCodeAware = true;

  public shipmentTypes = [
    {
      shipmentTypeCode: '1',
      shipmentTypeName: 'Insurance Only',
    },
    {
      shipmentTypeCode: '2',
      shipmentTypeName: 'Freight & Insurance',
    },
  ];
  public carriers: ICustomerCarrier[];
  public destinations = ['Domestic', 'International'];
  public serviceTypes: IReportService[];
  public filteredServices: IReportService[];
  public countries: ICountry[];

  public isCountriesLoading = false;
  public isCarriersLoading = false;
  public isServiceTypesLoading = false;

  public trackingNumberValidationSubscription: Subscription;
  public isTrackingNumberValidating = false;
  public isTrackingNumberValid = false;
  public isEditingExistingData = false;

  @Input() public rowToEdit: IReportShipmentEU;

  private selectedCarrierCode: string;

  private getReportServiceSubscription: Subscription;


  public constructor(private readonly formBuilder: UntypedFormBuilder,
    public readonly appState: AppState,
    private readonly lookupService: LookupService,
    private readonly shipmentService: ShipmentService,
    private readonly utilityService: UtilityService,
    private readonly userService: UserService,
    private readonly notificationService: NotificationService,
    private readonly validationService: ValidationService,
    private readonly reportShipmentService: ReportShipmentService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly dialog: MatDialog,
    public dialogRef: MatDialogRef<ReportShipmentsSingleUploadComponent>,

  ) {
    this.customer = this.appState.customer$();
    this.user = this.appState.user$();
    this.loadInitialValues();
  }

  public ngOnInit(): void {
    if (this.user && this.userService.isEURUser(this.user.CountryCode)) {
      this.userCurrency = 'EUR';
    } else if (this.user && this.userService.isGBUser(this.user)) {
      this.userCurrency = 'GBP';
    }

    const isAgreeToTermsValidators = this.rowToEdit ? [] : [Validators.requiredTrue];

    this.formGroup = this.formBuilder.group({
      userId: this.user.UserId,
      customerId: this.customer.CustomerId,

      generalInformation: this.formBuilder.group({
        carrier: ['', Validators.compose([Validators.required])],
        serviceType: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
        coverage: ['', Validators.compose([Validators.required])],
        coverageCurrency: [this.userCurrency, Validators.compose([Validators.required])],
        trackingNumber: new FormControl('', {
          validators: [Validators.required],
          asyncValidators: [this.trackingNumberValidator.bind(this)],
        }),
        shipmentType: ['', Validators.compose([Validators.required])],
        shipFromLocation: ['', Validators.compose([Validators.required])],
        shipToLocation: ['', Validators.compose([Validators.required])],
      }, { updateOn: 'blur' }),

      shippingInformation: this.formBuilder.group({
        companyName: [''],
        phoneNumber: [''],
        firstName: ['', Validators.compose([Validators.required])],
        lastName: ['', Validators.compose([Validators.required])],
        shipToZipCode: ['', Validators.compose([
          Validators.required,
          Validators.pattern(/^(|[0-9\-]+)$/),
        ])],
        province: [''],
        city: ['', Validators.compose([Validators.required])],
        weight: [''],
        referenceNumber: [''],
      }, { updateOn: 'blur' }),

      isAgreeToTerms: [false, Validators.compose(isAgreeToTermsValidators)],
    },);

    this.monitorValueChanges();

    this.formGroup.statusChanges.subscribe(() => this.onFormGroupChanges());

    if (this.rowToEdit !== undefined) {
      this.formGroup.patchValue({
        generalInformation: {
          carrier: this.rowToEdit.Carrier,
          serviceType: this.rowToEdit.Service,
          coverage: this.rowToEdit.Coverage,
          trackingNumber: this.rowToEdit.TrackingNumber,
          shipmentType: this.rowToEdit.ShipmentType?.toString(),
          shipFromLocation: this.rowToEdit.ShipFromCountry,
          shipToLocation: this.rowToEdit.ShipToCountry,
        },
        shippingInformation: {
          companyName: this.rowToEdit.ShipToCompanyName,
          phoneNumber: this.rowToEdit.ShipToPhone,
          firstName: this.rowToEdit.ShipToFirstName,
          lastName: this.rowToEdit.ShipToLastName,
          shipToZipCode: this.rowToEdit.ShipToZip,
          province: this.rowToEdit.ShipToProvince,
          city: this.rowToEdit.ShipToCity,
          weight: this.rowToEdit.Weight,
          referenceNumber: this.rowToEdit.Reference,
        },
      });

      this.isEditingExistingData = true;

      this.formGroup.markAllAsTouched();
    }
  }

  private loadInitialValues(): void {
    this.isCarriersLoading = true;
    this.lookupService.getCustomerCarriers(this.customer.CustomerId)
      .subscribe(
        (carriers) => {
          this.carriers = carriers;
          this.utilityService.delay(() => {
            this.isCarriersLoading = false;
          });
        },
      );

    this.isCountriesLoading = true;
    this.shipmentService.getShipToCountries(Carriers[Carriers.Ups])
      .subscribe(
        (countries) => {
          this.countries = _.cloneDeep(_.sortBy(countries, ['CountryName']));
          this.utilityService.delay(() => {
            this.isCountriesLoading = false;
          });
        },
      );
  }

  private monitorValueChanges(): void {

    (<UntypedFormGroup>this.formGroup.controls.generalInformation).controls.carrier.valueChanges
      .subscribe(
        (carrierCode) => {
          this.selectedCarrierCode = carrierCode;
          (<UntypedFormGroup>this.formGroup.controls.generalInformation).controls.serviceType.reset();
          this.getServiceTypes();
        },
      );

    (<UntypedFormGroup>this.formGroup.controls.generalInformation).controls.serviceType.valueChanges
      .subscribe(
        (serviceTypeCode) => {
          if (!serviceTypeCode || !this.serviceTypes) {
            return;
          }

          const selectedService = this.serviceTypes.find(item => item.ServiceCode === serviceTypeCode && item.CarrierCode === this.selectedCarrierCode);
          if (!selectedService) {
            return;
          }

          this.setMaxInsuredValue(selectedService.ReportMaxCoverage);
        },
      );

    (<UntypedFormGroup>this.formGroup.controls.generalInformation).controls.shipToLocation.valueChanges
      .subscribe(
        (countryCode) => {
          if (!countryCode || !this.countries || !this.countries.length) {
            return;
          }

          const country = this.countries.find(item => item.CountryCode === countryCode);
          if (!country) {
            return;
          }

          this.isPostalCodeAware = country.IsPostalCodeAware;
          const postalCodeForm = (<UntypedFormGroup>this.formGroup.controls.shippingInformation).controls.shipToZipCode;
          if (country.IsPostalCodeAware) {
            this.validationService.setFormControlValidators(postalCodeForm, Validators.compose([Validators.pattern(/^(|[0-9\-]+)$/)]));
          } else {
            this.validationService.clearFormControlValidators([postalCodeForm]);
          }
        },
      );
  }

  private getServiceTypes(): void {
    const serviceTypeControl = (<UntypedFormGroup>this.formGroup.controls.generalInformation).controls.serviceType;
    if (!this.selectedCarrierCode) {
      serviceTypeControl.setValue('');
      //serviceTypeControl.disable();
      return;
    }

    this.isServiceTypesLoading = true;
    if (this.serviceTypes) {
      this.handleGetReportServicesSuccess(this.serviceTypes);
    } else {
      this.utilityService.clearSubscriptions([this.getReportServiceSubscription]);
      this.getReportServiceSubscription = this.reportShipmentService.getReportServices()
        .subscribe(
          services => this.handleGetReportServicesSuccess(services),
          err => this.handleGetReportServicesFailure(err),
        );
    }
  }

  private handleGetReportServicesSuccess(services: IReportService[]): void {
    const serviceTypeControl = (<UntypedFormGroup>this.formGroup.controls.generalInformation).controls.serviceType;
    serviceTypeControl.enable();

    const carrierCode = (<UntypedFormGroup>this.formGroup.controls.generalInformation).controls.carrier.value.toString();
    this.serviceTypes = services;
    const isDomestic = (<UntypedFormGroup>this.formGroup.controls.generalInformation).controls.shipToLocation.value === (<UntypedFormGroup>this.formGroup.controls.generalInformation).controls.shipFromLocation.value;
    this.filteredServices = _.cloneDeep(this.serviceTypes).filter(
      (service) => {
        return service.IsAllowed && carrierCode.toUpperCase() === service.CarrierCode.toUpperCase() && service.IsDomestic === isDomestic;
      },
    );

    this.isServiceTypesLoading = false;
  }

  private handleGetReportServicesFailure(err): void {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed Getting Carrier Services',
      NotificationType.ERROR);
    this.isServiceTypesLoading = false;
  }

  public onFormSubmit(event, form): void {
    event.preventDefault();

    const reportShipment: IReportShipmentEU = {
      Carrier: form.generalInformation.carrier,
      Service: form.generalInformation.serviceType,
      Coverage: form.generalInformation.coverage,
      CoverageCurrencyCode: form.generalInformation.coverageCurrency,
      TrackingNumber: form.generalInformation.trackingNumber.replace(/\s/g, ''),
      ShipmentType: form.generalInformation.shipmentType,
      ShipFromCountry: form.generalInformation.shipFromLocation,
      ShipToCountry: form.generalInformation.shipToLocation,

      ShipToCompanyName: form.shippingInformation.companyName,
      ShipToFirstName: form.shippingInformation.firstName,
      ShipToLastName: form.shippingInformation.lastName,
      ShipToPhone: form.shippingInformation.phoneNumber,
      ShipToZip: form.shippingInformation.shipToZipCode,
      ShipToProvince: form.shippingInformation.province,
      ShipToCity: form.shippingInformation.city,
      Weight: this.userService.standardizeLocalizedNumber(this.user, form.shippingInformation.weight).toString(),
      Reference: form.shippingInformation.referenceNumber,

      MasterCustomerID: this.customer.CustomerId,
      UserId: form.userId,
      CustomerId: form.customerId,

    };

    this.reportShipmentService.saveReportShipmentEU(reportShipment)
      .subscribe(
        result => this.handleSaveReportShipmentEUSuccess(result),
        err => this.handleSaveReportShipmentEUFailure(err),
      );
  }

  private handleSaveReportShipmentEUSuccess(result): void {
    this.notificationService.notify(
      'Shipment Reported Successfully',
      'Success',
      NotificationType.SUCCESS);
    this.dialogRef.close(true);
  }

  private handleSaveReportShipmentEUFailure(err): void {
    this.notificationService.notify(
      this.errorHandlerService.getHttpErrorMessage(err),
      'Failed saving report shipment',
      NotificationType.ERROR);
  }

  public isFormValid(): boolean {
    return this.formGroup.valid;
  }

  public openTermsAndConditionsDialog(event): void {
    event.preventDefault();

    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      width: '70%',
      data: {},
      maxWidth: '100%',
      panelClass: ['mobile-fullscreen-dialog'],
    };

    let dialogRef: MatDialogRef<UserTermsConditionsComponent>;
    dialogRef = this.dialog.open(UserTermsConditionsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  private setMaxInsuredValue(value: number): void {
    // [MV3-2084] Limit maximum value for insured value in the message to 2 decimal places.
    this.maxInsuredValue = +value.toFixed(2);
    const control = (<UntypedFormGroup>this.formGroup.controls.generalInformation).controls.coverage;

    if (this.maxInsuredValue <= 0) {
      this.validationService.setFormControlValidators(
        control,
        Validators.compose([Validators.required, Validators.min(1)]));

      return;
    }

    this.validationService.setFormControlValidators(
      control,
      Validators.compose([Validators.required, Validators.min(1), Validators.max(this.maxInsuredValue)]));
  }

  private onFormGroupChanges(): void {
    if (this.formGroup.status === 'VALID') {
      const currentRowData: IReportShipmentEU = {
        Carrier: this.formGroup.get('generalInformation.carrier').value,
        Service: this.formGroup.get('generalInformation.serviceType').value,
        Coverage: this.formGroup.get('generalInformation.coverage').value,
        CoverageCurrencyCode: this.formGroup.get('generalInformation.coverageCurrency').value,
        TrackingNumber: this.formGroup.get('generalInformation.trackingNumber').value,
        ShipmentType: this.formGroup.get('generalInformation.shipmentType').value,
        ShipFromCountry: this.formGroup.get('generalInformation.shipFromLocation').value,
        ShipToCountry: this.formGroup.get('generalInformation.shipToLocation').value,

        ShipToCompanyName: this.formGroup.get('shippingInformation.companyName').value,
        ShipToFirstName: this.formGroup.get('shippingInformation.firstName').value,
        ShipToLastName: this.formGroup.get('shippingInformation.lastName').value,
        ShipToPhone: this.formGroup.get('shippingInformation.phoneNumber').value,
        ShipToZip: this.formGroup.get('shippingInformation.shipToZipCode').value,
        ShipToProvince: this.formGroup.get('shippingInformation.province').value,
        ShipToCity: this.formGroup.get('shippingInformation.city').value,
        Weight: this.formGroup.get('shippingInformation.weight').value,
        Reference: this.formGroup.get('shippingInformation.referenceNumber').value,

        ShipDate: this.rowToEdit?.ShipDate,

        MasterCustomerID: this.customer.CustomerId,
        UserId: this.user.UserId,
        CustomerId: this.customer.CustomerId,
      };

      this.reportShipmentService.rowAfterEdit$.next({ data: currentRowData, isValid: true });
    } else if (this.formGroup.status === 'INVALID') {
      this.reportShipmentService.rowAfterEdit$.next({ data: null, isValid: false });
      this.logInvalidControls();
    }
  }

  private logInvalidControls(): void {
    const invalidControls = [];
  
    const logGroupControls = (group: FormGroup, parentName: string) => {
      Object.keys(group.controls).forEach(name => {
        const control = group.controls[name];
        const controlPath = parentName ? `${parentName}.${name}` : name;
  
        if (control instanceof FormGroup) {
          logGroupControls(control, controlPath);
        } else if (control.status === 'INVALID') {
          invalidControls.push(controlPath);
        }
      });
    };
  
    logGroupControls(this.formGroup, '');
  
    console.log('Invalid Controls:', invalidControls);
  }

  private trackingNumberValidator(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.reportShipmentService.validateTrackingNumber(control.value).pipe(
      map(() => {
        // If the service call succeeds, the tracking number is valid
        return null; // No errors, validation passed
      }),
      catchError(err => {
        if (err.status === 500 && err.error && err.error.Code === 7) {
          // Tracking number already exists
          this.reportShipmentService.rowAfterEdit$.next({ data: null, isValid: false });
          return of({ existed: true });
        } else {
          // Some other error occurred
          console.error('Error in trackingNumberValidator', err);
          return of({ serverError: true });
        }
      })
    );
  }
}
