import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { ReportHistoryService } from '../services/report-history.service';
import { UserService } from '../../shared/services/user/user.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { NotificationType } from '../../shared/models/notification-type';
import { User } from '../../shared/services/user/models/user.model';
import { ReportShipmentEuDialogComponent } from './report-shipment-eu-dialog/report-shipment-eu-dialog.component';
import { ReportShipmentUsDialogComponent } from './report-shipment-us-dialog/report-shipment-us-dialog.component';
import { Customer } from '../../shared/models/customer/customer.model';
import { SharedService } from '../../core/services/shared.service';
import { IRowColumn } from '../../shared/components/responsive-table/models/row-column.interface';
import { IReportedShipmentPackage } from '../../shared/models/shipments/reported-shipment-package.interface';
import { CarrierPipe } from '../pipes/carrier.pipe';
import { IRowActionData } from '../../shared/components/responsive-table/models/row-action-data.interface';
import { TrackingDetailsDialogComponent } from './tracking-details-dialog/tracking-details-dialog.component';
import { Package } from '../../ship/models/package.model';
import { IShipmentPackage } from '../../shared/models/shipments/shipment-package.interface';
import { DialogService } from '../../shared/services/dialog/dialog.service';
import { IReportedShipment } from '../../shared/models/shipments/reported-shipment.interface';
import { PaginationInstance } from 'ngx-pagination';
import { UtilityService } from '../../shared/services/utility/utility.service';
import { SiteConfig } from '../../../config/site-config';
import { UserCurrencyPipe } from '../../shared/pipes/user-currency/user-currency.pipe';
import { SpinnerService } from '../../core/services/spinner/spinner.service';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import * as FileSaver from 'file-saver';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {ISearchReportedShipment} from '../../shared/models/shipments/search-reported-shipment.interface';
import { DataTableDirective } from 'angular-datatables';

declare let $: any;

@Component({
  selector: 'upsc-report-history',
  templateUrl: './report-history.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportHistoryComponent implements OnInit, OnDestroy {
  public user: User;
  public customer: Customer;
  public searchFormGroup: UntypedFormGroup;
  public showDetails = true;
  public reportedShipmentList: any[];
  public trackingShipmentDetail: any;
  public noRecordsFound = false;
  public daysOld = '15';
  private pageIndex = 1;
  public NoMoreRecords = 'NoMoreRecordsFound';
  public customMessage: string;
  public voidshipmentid: string;
  public userCurrency: string;
  public searchMode = false;

  public reportedShipmentItems: any[];
  public reportedShipmentColumns: IRowColumn[];
  public totalItemCount: number;
  dateSting = '';
  CreateDate : Date;
  // Pagination
  public paginate: PaginationInstance;
  public tablePageSize = 25;
  public tablePageIndex = 0;
  public isDataLoading = false;
  private totalItems = 0;
  private getReportHistorySubscription: Subscription;
  private isEmpty = true;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  
  public dtOptions: any = {};
  public dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild('CustomInformation') customInfo: ElementRef;

  public constructor(private dialog: MatDialog,
                     private userService: UserService,
                     private sharedService: SharedService,
                     private notificationService: NotificationService,
                     private dialogService: DialogService,
                     private translate: TranslateService,
                     private carrierPipe: CarrierPipe,
                     private decimalPipe: DecimalPipe,
                     private userCurrencyPipe: UserCurrencyPipe,
                     private utilityService: UtilityService,
                     private reporthistoryservice: ReportHistoryService,
                     private translateService: TranslateService,
                     private errorHandlerService: ErrorHandlerService,
                     private spinnerService: SpinnerService,
                     private formBuilder: UntypedFormBuilder,
                     private readonly appState: AppState,
  ) {
    this.user = this.appState.user$();
    this.customer = this.appState.customer$();

    this.userCurrency = this.userCurrencyPipe.transform(this.user);

    this.reportedShipmentColumns = [
      { i18n: 'Account', itemProperty: 'account', classes: 'text-center' },
      { i18n: 'Carrier', itemProperty: 'carrier', classes: 'text-center' },
      { i18n: 'ReportDate', itemProperty: 'reportDate' },
      { i18n: 'ShipDate', itemProperty: 'shipDate' },
      { i18n: 'TrackingNumber', itemProperty: 'trackingNumber', classes: 'all' },
      { i18n: 'Coverage', itemProperty: 'insuredValue', classes: 'text-right' },
      { i18n: 'Status', itemProperty: 'status', classes: 'all' },
      { i18n: 'ServiceType', itemProperty: 'serviceType', classes: 'none' },
      { i18n: 'ShipmentType', itemProperty: 'shipmentType', classes: 'none' },
      { i18n: 'Reference', itemProperty: 'referenceNumber', classes: 'none' },
      { i18n: 'Confirmation', itemProperty: 'confirmationNumber', classes: 'none' },
      { title: '', itemProperty: 'rowActions', isRowActions: true, classes: 'all' },
    ];
    this.updatePaginateConfig();
    this.translateService.onLangChange.subscribe(() =>{
      this.updateDTOptionsLanguage();
  })
    this.GetReportShipment('', this.daysOld, this.pageIndex, this.tablePageSize);
  }

  public ngOnInit() {
    this.searchFormGroup = this.formBuilder.group({
      keyword: [''],
    });

    this.searchFormGroup.controls.keyword.valueChanges.subscribe(value => {
      if (!value) {
        this.tablePageIndex = 1;
        this.GetReportShipment(value, this.daysOld, this.pageIndex, this.tablePageSize);
      }
    });

    this.dtOptions = SiteConfig.tableConfig;
    this.dtOptions.language = {
      emptyTable: this.translateService.instant('NoRecordsFound')
    };
    this.updatePaginateConfig();
  }

  public ngOnDestroy() {
    this.utilityService.clearSubscriptions([
      this.getReportHistorySubscription,
    ]);
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        if (this.isEmpty) {
          dtInstance.destroy();
        }
        
        // Call the dtTrigger to rerender again
        this.dtTrigger.next(null);
    });
}

public updateDTOptionsLanguage(): void{
    this.dtOptions.language = {
            emptyTable: this.translateService.instant('NoRecordsFound')
        };

    this.rerender();
}
  private updatePaginateConfig() {
    this.paginate = {
      totalItems: this.totalItems,
      currentPage: this.tablePageIndex,
      itemsPerPage: this.tablePageSize,
    };
  }

  public openReportShipmentModal(event) {
    event.preventDefault();

    const isEU = this.user.IsEuropean;
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      data: {
        user: this.user,
        customer: this.customer,
      },
      maxWidth: '100%',
      panelClass: ['mobile-fullscreen-dialog'],
    };

    if (isEU) {
      dialogConfig.width = '800px';
    }

    const dialogRef = isEU
        ? this.dialog.open(ReportShipmentEuDialogComponent, dialogConfig)
        : this.dialog.open(ReportShipmentUsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
        (result) => {
          if (!result) {
            return;
          }

          const keyword = this.searchFormGroup.controls['keyword'].value;
          this.GetReportShipment(keyword, this.daysOld, this.pageIndex, this.tablePageSize);
        },
    );
  }

  public GetReportShipment(keyword: string, daysOld: string, pageIndex: number, pageSize: number) {
    this.utilityService.clearSubscriptions([this.getReportHistorySubscription]);

    this.isDataLoading = true;
    this.searchMode = false;
    this.getReportHistorySubscription = this.reporthistoryservice.getReportedShipments(keyword, daysOld, pageIndex.toString(), pageSize + '')
      .subscribe(
        (packages) => {
          this.tablePageIndex = pageIndex;
          this.tablePageSize = pageSize;

          if (!packages || !packages.length) {
            this.isEmpty = true;
            this.reportedShipmentList = [];
            this.noRecordsFound = true;
            this.totalItems = 0;
            this.dtTrigger.next(null);
            this.updatePaginateConfig();
            this.isDataLoading = false;
            return;
          }

          if (packages && packages.length) {
            this.isEmpty = false;
            this.totalItemCount = packages[0].TotalShipments;
            this.totalItems = packages[0].TotalShipments;
          }

          if (this.pageIndex === 1) {
            this.reportedShipmentList = packages;
          } else {
            this.reportedShipmentList = this.reportedShipmentList.concat(packages);
          }

          this.reportedShipmentItems = packages.map(item => this.createReportedShipmentItem(item));

          if (this.reportedShipmentList.length === 0) {
            this.noRecordsFound = true;
          } else {
            this.noRecordsFound = false;
          }
          this.dtTrigger.next(null);
          this.updatePaginateConfig();

          this.isDataLoading = false;
        },
        (err) => {
          this.dtTrigger.next(null);
          this.isDataLoading = false;
        },
      );
  }

  private createReportedShipmentItem(shipment: IReportedShipmentPackage) {
    return {
      shipmentId: shipment.ShipmentId,
      account: shipment.CustomerId,
      carrier: this.carrierPipe.transform(shipment.CarrierCode),
      reportDate: moment(shipment.ReportDate, 'MM/DD/YYYY').format('ll'),
      shipDate: moment(shipment.ShipDate, 'MM/DD/YYYY').format('ll'),
      trackingNumber: shipment.TrackingNumber,
      insuredValue: `<strong>${this.decimalPipe.transform(shipment.InsuredValue, '1.2-2')} USD</strong>`,
      status: this.getShipmentStatusHtml(shipment),
      serviceType: shipment.Description,
      shipmentType: this.getShipmentTypeHtml(shipment),
      referenceNumber: shipment.Reference,
      confirmationNumber: shipment.Confirmation,
      rowActions: this.getRowActions(shipment),
    };
  }

  private getShipmentTypeHtml(shipment: IReportedShipmentPackage): string {
    switch (+shipment.ShipmentType) {
      case 1:
        return `<span class="shipment-type">${this.translate.instant('InsuranceOnly')}</span>`;
      case 2:
        return `<span class="shipment-type">${this.translate.instant('ShipAndInsure')}</span>`;
    }
  }

  private getShipmentStatusHtml(shipment: IReportedShipmentPackage) {
    const status = [0, 1].includes(shipment.Status) ? this.translate.instant('OK') : this.translate.instant('Void');

    return `<span class="status ${_.kebabCase(status)}">${status}</span>`;
  }

  private getRowActions(shipment) {
    
    const shouldShowActions = this.CalculateDays(shipment.ShipDate) < 24 && shipment.Status !== 3;
    if (!shouldShowActions) {
      return [];
    }

    const actions = [];
    actions.push({
      key: 'void',
      i18n: 'Void',
      icon: 'cancel',
    });

    return actions;
  }

  public onReportedShipmentActionClicked(actionData: IRowActionData<any>) {
    switch (actionData.key) {
      case 'void':
        this.voidshipmentid = actionData.data.shipmentId;
        //$('#voidconfirmation').modal('show');
        this.deleteReportedShipmentConfirmation(this.voidshipmentid);
        break;
    }
  }

  deleteReportedShipmentConfirmation(shipmentId: string) {
    this.dialogService.confirm('', this.translate.instant('Msg_RemoveShipmentConfirmation'))
      .subscribe(
        (isConfirmation) => {
          if (isConfirmation) {
            this.DeleteReportedShipment(shipmentId);
          }
        });
  }

  DeleteReportedShipment(shipmentId: string) {
    this.reporthistoryservice.deleteReportedShipment(shipmentId)
      .subscribe(
        (response) => {
          //this.customMessage = response.statusText || (response.json() ? response.json().data.toString() : '');
          this.notificationService.notify('', 'Reported Shipment successfully voided!', NotificationType.SUCCESS);
          const keyword = this.searchFormGroup.controls['keyword'].value;
          this.GetReportShipment(keyword, this.daysOld, this.tablePageIndex, this.tablePageSize);
        },
        (error) => {
        });
  }

  TrackShipmentDetails(event, shipment: IReportedShipmentPackage) {
    event.preventDefault();

    this.reporthistoryservice.getTrackingDetails(shipment.ShipmentId, 'reported')
      .subscribe(
        (result) => {
          this.trackingShipmentDetail = result;

          if (result.ReportedPackage) {
            result.ReportedPackage.ShipmentId = shipment.ShipmentId;
          }

          this.openShipmentDetailsDialog(result.ReportedPackage);
        },
        (err) => {
        });
  }

  private openShipmentDetailsDialog(trackingDetailsPackage: IShipmentPackage | IReportedShipment) {
    if (!trackingDetailsPackage) {
      this.notificationService.notify(
        this.translate.instant('ShipmentPackageNotFound_Msg'),
        this.translate.instant('ShipmentPackageNotFound_Title'),
        NotificationType.ERROR);
      return;
    }

    const shipmentPackage = Object.assign(new Package(), _.cloneDeep(trackingDetailsPackage));

    const dialogConfig: MatDialogConfig = {
      disableClose: true,
      width: '800px',
      data: {
        shipmentPackage,
        shipmentType: 'reported',
      },
    };

    const dialogRef = this.dialog.open(TrackingDetailsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      (result) => {
        if (!result) {
          return;
        }
      },
    );
  }

  onDaysChange(daysOld: string) {
    this.daysOld = daysOld;
    this.pageIndex = 1; // resetting the page index to come from first for newly selected days range.
    this.tablePageIndex = 0;
    const keyword = this.searchFormGroup.controls['keyword'].value;
    this.GetReportShipment(keyword, this.daysOld, this.pageIndex, this.tablePageSize);
  }

  CalculateDays(shipDate: string) {
    //let oneDay = 24 * 60 * 60 * 1000;
  const modifiedDate = shipDate.toString();
  if(modifiedDate.indexOf('.')>= 0){
  //Changing the date format from (DD/MM/YYYY) to (MM/DD/YYYY) for german language
  this.dateSting = modifiedDate.substr(3,2)+'/'+modifiedDate.substr(0,2)+'/'+modifiedDate.substr(6,4);  
  this.CreateDate = new Date(this.dateSting);  
  return Math.ceil((new Date().getTime() - this.CreateDate.getTime()) / 1000 / 60 / 60);
  }
  else{
  this.CreateDate = new Date(modifiedDate);
  return Math.ceil((new Date().getTime() - this.CreateDate.getTime()) / 1000 / 60 / 60);
  }
  }

  /* Not invoked anywhere
   ShowMore() {
    this.pageIndex = this.pageIndex + 1;
    this.tablePageIndex = this.tablePageIndex + 1;
    this.GetReportShipment(this.daysOld, this.pageIndex, this.tablePageSize);
  }
  toggleClass(event) {
    if (event.srcElement.classList.contains('glyphicon-plus')) {
      event.srcElement.className = 'glyphicon glyphicon-minus';
    } else {
      event.srcElement.className = 'glyphicon glyphicon-plus';
    }
  }*/

  public onPageChanged(pageNumber: number) {
        const keyword = this.searchFormGroup.controls['keyword'].value;
        this.GetReportShipment(keyword, this.daysOld, pageNumber, this.tablePageSize);
  }


  //#region Export Data
  // Final Code for Download CSV Function
  download() {
    if (this.reportedShipmentList && this.reportedShipmentList.length > 0) {
      this.spinnerService.show();
      const keyword = this.searchFormGroup.controls['keyword'].value;
      this.reporthistoryservice.getReportedShipments(keyword, this.daysOld,
        '1',
        this.totalItemCount.toString())
        .subscribe(
          (dataToExport) => {
            this.spinnerService.hide();
            this.CreateFile(dataToExport);
          },
          (err) => {
            this.notificationService.notify(
              this.errorHandlerService.getHttpErrorMessage(err),
              this.translateService.instant('ExportFailed'),
              NotificationType.ERROR);
            this.spinnerService.hide();
          });

    }
  }

  CreateFile(dataToExport) {
    const csvData = this.ConvertToCSV(dataToExport);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, 'Report-shipment-history_' + new Date().toLocaleDateString() + '.csv');
  }

  // convert Json to CSV data in Angular2
  ConvertToCSV(objArray) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';


    row += 'Account,Carrier,Report Date,Ship Date,Tracking #,Confirmation,Coverage,Status';

    str += row + '\r\n'; //append columns to str and moves to next line

    str = this.fillColumns(array, str, row);

    return str;
  }

  fillColumns(array, stringToExport, row) {
    if (array && array.length > 0) {
      for (let i = 0; i < array.length; i++) {
        let line = '';
        if (row.includes('Account'))      
        {var splitAccount = array[i].CustomerId.replaceAll(',',' ');}            
         line += splitAccount + ',';
        if (row.includes('Carrier'))
        {var splitCarrier = array[i].CarrierCode.replaceAll(',',' ');}            
        line += splitCarrier + ',';
        if (row.includes('Report Date'))
        {var splitReportDate = array[i].ReportDate.replaceAll(',',' ');}            
        line += splitReportDate + ',';         
        if (row.includes('Ship Date'))        
        {var splitShipdate = array[i].ShipDate.replaceAll(',',' ');}            
        line += splitShipdate + ','; 
        if (row.includes('Tracking #'))
        {var splitTracking = array[i].TrackingNumber.replaceAll(',',' ');}            
        line += splitTracking + ',';  
        if (row.includes('Confirmation'))
        {var splitConfirmation= array[i].Confirmation.replaceAll(',',' ');}            
        line += splitConfirmation + ',';  
        if (row.includes('Coverage'))
        {var splitCoverage = this.decimalPipe.transform(array[i].InsuredValue.toString().replaceAll(',',' '), '1.2-2');}           
        line += '\"' +splitCoverage + ' ' + this.userCurrency.toString()+ '\",';  
        if (row.includes('Status'))
          {const splitZip= array[i].Status;
          if(splitZip === 0 || splitZip === 1)
          {
            line += 'OK,';
          }
          else if(splitZip === 3)
          {
            line += 'Void,';
          }
        }
        
        line = line.slice(0, -1);
        stringToExport += line + '\r\n';
      }
    }
    return stringToExport;
  }

  public onSearchSubmit(event, form) {
    event.preventDefault();
    this.GetReportShipment(form.keyword, this.daysOld, this.pageIndex, this.tablePageSize);
  }

  
  //#endregion
}
