import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatOption, MatSelect } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerService } from 'app/core/services/spinner/spinner.service';
import { ReportShipmentService } from 'app/history/services/report-shipment.service';
import { ReportShipmentsPopupsComponent } from 'app/report-shipments-popups/report-shipments-popups.component';
import { NotificationType } from 'app/shared/models/notification-type';
import { IReportedShipmentFileUpload } from 'app/shared/models/shipments/reported-shipment-file-upload.interface';
import { NotificationService } from 'app/shared/services/notification/notification.service';
import { User } from 'app/shared/services/user/models/user.model';
import { UserService } from 'app/shared/services/user/user.service';
import { NgxPaginationModule, PaginationInstance } from 'ngx-pagination';
import {
  ReportShipmentsRecentCutomPaginatorComponent
} from '../report-shipments-recent-cutom-paginator/report-shipments-recent-cutom-paginator.component';
import { ElementBlockerModule } from '../shared/components/element-blocker/element-blocker.module';

@Component({
  selector: 'upsc-report-shipments-recent-uploads',
  templateUrl: './report-shipments-recent-uploads.component.html',
  styleUrls: ['./report-shipments-recent-uploads.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReportShipmentsRecentCutomPaginatorComponent,
    TranslateModule,
    ElementBlockerModule,
    MatFormFieldModule,
    MatSelect,
    MatOption,
    MatDatepickerInput,
    ReactiveFormsModule,
    MatDatepickerToggle,
    MatDatepicker,
    NgxPaginationModule,
    MatButton,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    MatInput,
  ],
})
export class ReportShipmentsRecentUploadsComponent implements OnInit {

  public isLoadingData: boolean = true;
  public recentUploadsData: unknown[] = [];
  public showRefreshButtons: boolean = false;
  public refreshingUploadStatus: boolean = false;
  public tableHeaders = ['File Name', 'Expire In', 'Imported On', 'Number of Shipments', 'Import Status', 'Imported By']
  public allUploads: IReportedShipmentFileUpload[];
  private _fileUploads: IReportedShipmentFileUpload[] = [];
  public currentTime: Date;
  public tableDateFormat = 'MM/dd/yyyy';
  public user: User;

  public startDateFormControl = new FormControl<Date | null>({ value: null, disabled: true });
  public endDateFormControl = new FormControl<Date | null>({ value: null, disabled: true });
  public minDate: Date;
  public maxDate: Date;
  public minEndDate: Date;
  public maxEndDate: Date;

  public paginate: PaginationInstance = { totalItems: 0, currentPage: 1, itemsPerPage: 5 };
  public pageIndex = 1;
  public pageSize = 100;

  public statusOptions: string[] = ['All', 'Pending', 'Accepted', 'Cancelled', 'Expired'];
  public selectedStatus: string = 'All';

  @Input()
  public set fileUploads(value: IReportedShipmentFileUpload[]) {
    if (value) {
      this._fileUploads = value;
      this.allUploads = [...value]; // Create a shallow copy
      this.isLoadingData = false;
      this.paginate.totalItems = this._fileUploads.length;
    }
  }

  public get fileUploads(): IReportedShipmentFileUpload[] {
    return this._fileUploads;
  }
  @ViewChild('startDateInput', {
    read: MatInput,
  }) public startDateInput: MatInput;

  @ViewChild('endDateInput', {
    read: MatInput,
  }) public endDateInput: MatInput;

  public constructor(
    public dialog: MatDialog,
    private spinnerService: SpinnerService,
    private reportShipmentService: ReportShipmentService,
    private notificationService: NotificationService,
    private cdRef: ChangeDetectorRef,
    private userService: UserService,
  ) {
    if (!this.user) {
      this.userService.getUser()
        .subscribe((user) => {
          this.setUserValues(user);
        });
    }
  }

  public ngOnInit(): void {
    this.currentTime = new Date();
    setInterval(() => {
      this.currentTime = new Date();
      this.cdRef.detectChanges();
    }, 60000);
  }

  public updateMinDate(event: any): void {
    // Clear end date value if current endDateInput is less than previous minEndDate
    const date1 = new Date(this.endDateInput.value);
    const date2 = new Date(event.value);

    if (date2 > date1) {
      this.endDateInput.value = '';
    }

    this.minEndDate = new Date(event.value);
    this._fileUploads = this.allUploads.filter(upload => upload.ImportedOn >= this.minEndDate);
    this.paginate.totalItems = this._fileUploads.length;
  }

  public updateMaxDate(event: any): void {
    // Clear end date value if current endDateInput is less than previous minEndDate
    const date1 = new Date(this.endDateInput.value);
    const date2 = new Date(event.value);

    if (date2 > date1) {
      this.endDateInput.value = '';
    }

    this.maxEndDate = new Date(event.value);
    this._fileUploads = this.allUploads.filter(upload => upload.ImportedOn <= this.maxEndDate);
    this.paginate.totalItems = this._fileUploads.length;
  }

  public timeUntil(date: Date): string {
    const now = this.currentTime;
    const futureDate = this.add12Hours(date);
    const diffMs = futureDate.getTime() - now.getTime();

    if (diffMs <= 0) {
      return '0h 0min';
    }

    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

    return `${diffHours}h ${diffMinutes}min`;
  }

  public downloadFile(fileId: number): void {
    this.spinnerService.show();
    this.reportShipmentService.getFileContent(fileId).subscribe({
      next: (file) => {
        this.spinnerService.hide();
        // Trigger download using the File object
        this.triggerFileDownload(file);
      },
      error: (error) => {
        this.spinnerService.hide();
        this.notificationService.notify(
          error.error,
          'Error downloading file',
          NotificationType.ERROR
        );
      },
    });
  }

  public downloadAndEdit(fileId: number): void {
    this.spinnerService.show();
    this.reportShipmentService.getFileContent(fileId).subscribe({
      next: (file) => {
        this.spinnerService.hide();
        this.reportShipmentService.fileDownloaded$.next({ file: file, fileId: fileId });
      },
      error: (error) => {
        this.spinnerService.hide();
        this.notificationService.notify(
          error.message,
          'Error downloading file',
          NotificationType.ERROR
        );
      },
    });
  }

  public paginationChange(paginationDetails): void {
    this.pageIndex = paginationDetails.pageIndex;
    this.pageSize = paginationDetails.pageSize;

    this.paginate.currentPage = this.pageIndex;
    this.paginate.itemsPerPage = this.pageSize;
  }

  public cancelRow(fileId: number): void {
    this.dialog.open(ReportShipmentsPopupsComponent, {
      width: '592px',
      minHeight: '215px',
      maxHeight: '344px',
      data: {
        title: 'Cancel Submission',
        message: 'Are you sure you want to cancel this upload?',
        noMessage: 'No, Don\'t Cancel',
        yesMessage: 'Yes, Cancel',
        fileId: fileId,
      },
    });
  }

  public submitRow(fileId: number): void {
    const currentRow = this._fileUploads.find(row => row.Id === fileId);
    this.reportShipmentService.downloadAndSubmitFile$.next(currentRow.Id);
    currentRow.ImportStatus = 'Accepted';
  }

  public filterByStatus(status: string): void {
    if (status === 'All') {
      this._fileUploads = [...this.allUploads];
    } else {
      this._fileUploads = this.allUploads.filter(upload => upload.ImportStatus === status);
    }

    this.paginate.totalItems = this._fileUploads.length;
  }

  private triggerFileDownload(file: File): void {
    const url = window.URL.createObjectURL(file);

    // Create a temporary anchor element to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Release the object URL
    window.URL.revokeObjectURL(url);
  }

  private add12Hours(date: Date): Date {
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() + 12);
    return newDate;
  }

  private setUserValues(user: User): void {
    this.user = user;

      switch (user?.CountryCode){
        case 'HK':
          this.tableDateFormat = 'yyyy/MM/dd HH:mm';
          break;
        case 'DE':
        case 'FR':
        case 'IT':
          this.tableDateFormat = 'dd/MM/yyyy HH:mm';
          break;
        case 'GB':
          this.tableDateFormat = 'dd/MM/yyyy HH:mm';
          break;
        default:
          this.tableDateFormat = 'MM/dd/yyyy HH:mm';
          break;
      }
  }
}
