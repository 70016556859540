import { Carrier } from '../enum/carrier.enum';

/**
 * [dbo].[CarrierServices]
 */
export const carrierServices: any[] = [
    { carrier: Carrier.AIRLINES, serviceCode: 'AF-INT', description: 'Air Freight' },
    { carrier: Carrier.AIRLINES, serviceCode: 'AIRLINES', description: 'AIRLINES' },
    { carrier: Carrier.APC, serviceCode: '01-DOM', description: 'NATIONAL OVERNIGHT' },
    { carrier: Carrier.APC, serviceCode: '01-INT', description: 'WORLDWIDE' },
    { carrier: Carrier.BRT, serviceCode: '01-DOM-EU', description: 'Express Service' },
    { carrier: Carrier.BRT, serviceCode: '01-INT-EU', description: 'Express Service International' },
    { carrier: Carrier.BRT, serviceCode: '02-DOM-EU', description: 'Priority Service' },
    { carrier: Carrier.BRT, serviceCode: '03-DOM-EU', description: '10:30 Service' },
    { carrier: Carrier.BRINKS, serviceCode: 'BRINKS', description: 'BRINKS' },
    { carrier: Carrier.CANADA, serviceCode: 'PC', description: 'Priority Courier' },
    { carrier: Carrier.CANADA, serviceCode: 'RP', description: 'Regular Parcel' },
    { carrier: Carrier.CANADA, serviceCode: 'XP', description: 'Xpresspost' },
    { carrier: Carrier.DHL, serviceCode: 'AC', description: 'AC-International Cargo' },
    { carrier: Carrier.DHL, serviceCode: 'CX', description: 'CX-International Express Service' },
    { carrier: Carrier.DHL, serviceCode: 'DHL', description: 'DHL' },
    { carrier: Carrier.DHL, serviceCode: 'EU-DOM', description: 'Express' },
    { carrier: Carrier.DHL, serviceCode: 'EU-INT', description: 'Express International' },
    { carrier: Carrier.DHL, serviceCode: 'IP', description: 'Parcel International' },
    { carrier: Carrier.DHL, serviceCode: 'OC', description: 'OC-Ocean Freight' },
    { carrier: Carrier.DHL, serviceCode: 'ON', description: 'ON-Overnight' },
    { carrier: Carrier.DHL, serviceCode: 'PR', description: 'PR-Next Afternoon' },
    { carrier: Carrier.DHL, serviceCode: 'SE', description: 'SE-Second Day' },
    { carrier: Carrier.DHL, serviceCode: 'SG', description: 'SG-Standard Ground' },
    { carrier: Carrier.DPD, serviceCode: '01-DOM', description: 'Domestic Express' },
    { carrier: Carrier.DPD, serviceCode: '01-DOM-DE', description: 'Express National' },
    { carrier: Carrier.DPD, serviceCode: '01-DOM-UK', description: 'UK NEXT DAY' },
    { carrier: Carrier.DPD, serviceCode: '01-INT', description: 'International Express' },
    { carrier: Carrier.DPD, serviceCode: '01-INT-DE', description: 'Express Weltweit' },
    { carrier: Carrier.DPD, serviceCode: '01-INT-UK', description: 'EUROPE NEXT DAY' },
    { carrier: Carrier.DPD, serviceCode: '02-DOM-DE', description: 'Now' },
    { carrier: Carrier.DPD, serviceCode: '02-INT-DE', description: 'DPD Classic Europa' },
    { carrier: Carrier.DPD, serviceCode: '03-DOM-DE', description: 'DPD Direct' },
    { carrier: Carrier.DPD, serviceCode: '03-DOM-UK', description: 'NORTHERN' },
    { carrier: Carrier.DPD, serviceCode: '04-DOM-DE', description: 'DPD Classic National' },
    { carrier: Carrier.DPD, serviceCode: '04-DOM-UK', description: 'SOUTHERN' },
    { carrier: Carrier.DPD, serviceCode: '05-DOM-EU', description: 'Classic' },
    { carrier: Carrier.DPD, serviceCode: '06-DOM-EU', description: 'Predict' },
    { carrier: Carrier.DPD, serviceCode: '07-DOM-EU', description: 'Pickup' },
    { carrier: Carrier.DPD, serviceCode: '08-DOM-EU', description: 'Chéquier' },
    { carrier: Carrier.DPD, serviceCode: '09-INT-EU', description: 'Classic Intercontinental' },
    { carrier: Carrier.DX, serviceCode: '01-DOM', description: 'Next Day pre 1pm' },
    { carrier: Carrier.DUNBAR, serviceCode: 'DOM-01', description: 'Dunbar' },
    { carrier: Carrier.EMS, serviceCode: 'EMS', description: 'EMS' },
    { carrier: Carrier.FEDEX, serviceCode: '01-DOM', description: 'Priority Overnight' },
    { carrier: Carrier.FEDEX, serviceCode: '01-INT', description: 'International Priority' },
    { carrier: Carrier.FEDEX, serviceCode: '02-DOM', description: '2 Day' },
    { carrier: Carrier.FEDEX, serviceCode: '2A', description: 'International Priority Express' },
    { carrier: Carrier.FEDEX, serviceCode: '03-DOM', description: '2 Day' },
    { carrier: Carrier.FEDEX, serviceCode: '03-INT', description: 'International Economy' },
    { carrier: Carrier.FEDEX, serviceCode: '05', description: 'Standard Overnight' },
    { carrier: Carrier.FEDEX, serviceCode: '06-DOM', description: 'First Overnight' },
    { carrier: Carrier.FEDEX, serviceCode: '06-INT', description: 'International First' },
    { carrier: Carrier.FEDEX, serviceCode: '07-INT', description: 'International Connect Plus' },
    { carrier: Carrier.FEDEX, serviceCode: '18', description: 'International Priority Direct Distribution' },
    { carrier: Carrier.FEDEX, serviceCode: '20', description: 'Express Saver' },
    { carrier: Carrier.FEDEX, serviceCode: '21', description: 'International MailService-Premium' },
    { carrier: Carrier.FEDEX, serviceCode: '31', description: 'International MailService-Standard' },
    { carrier: Carrier.FEDEX, serviceCode: '49', description: '2 Day AM' },
    { carrier: Carrier.FEDEX, serviceCode: '92', description: 'Ground' },
    { carrier: Carrier.FEDEX, serviceCode: 'CA-DOM', description: 'Intra-Canada' },
    { carrier: Carrier.FEDEX, serviceCode: 'EU-DOM', description: 'Europe 1st' },
    { carrier: Carrier.FEDEX, serviceCode: 'EU-INT', description: 'International Europe First' },
    { carrier: Carrier.FEDEX, serviceCode: 'FXFE', description: 'FedEx Freight' },
    { carrier: Carrier.FERRARI, serviceCode: '01-DOM', description: 'Ferrari Express' },
    { carrier: Carrier.FERRARI, serviceCode: 'FERRARI', description: 'FERRARI' },
    { carrier: Carrier.G4SI, serviceCode: '01-DOM', description: 'Next Day' },
    { carrier: Carrier.GCARGO, serviceCode: 'GCARGO', description: 'GCARGO' },
    { carrier: Carrier.GDEX, serviceCode: 'GDEX', description: 'GDEX' },
    { carrier: Carrier.GLS, serviceCode: '01-DOM-DE', description: 'Express Parcel' },
    { carrier: Carrier.GLS, serviceCode: '01-INT-DE', description: 'Europa Express Parcel' },
    { carrier: Carrier.GLS, serviceCode: '02-DOM-DE', description: 'Business Small Parcel' },
    { carrier: Carrier.GLS, serviceCode: '02-INT-DE', description: 'Global Express Parcel' },
    { carrier: Carrier.GLS, serviceCode: '03-DOM-DE', description: 'Business Parcel' },
    { carrier: Carrier.LA_POSTE, serviceCode: '01-DOM-EU', description: 'From the mailbox' },
    { carrier: Carrier.LA_POSTE, serviceCode: '01-INT-EU', description: 'From the mailbox International' },
    { carrier: Carrier.LA_POSTE, serviceCode: '02-DOM-EU', description: 'Relaix Pickup (Shop2Shop)' },
    { carrier: Carrier.LA_POSTE, serviceCode: '03-DOM-EU', description: 'From the post office' },
    { carrier: Carrier.LA_POSTE, serviceCode: '03-INT-EU', description: 'From the post office International' },
    { carrier: Carrier.LA_POSTE, serviceCode: '04-DOM-EU', description: 'Small parcel' },
    { carrier: Carrier.LA_POSTE, serviceCode: '04-INT-EU', description: 'Small parcel International' },
    { carrier: Carrier.MALCA, serviceCode: 'MALCA', description: 'MALCA' },
    { carrier: Carrier.MONDIAL_RL, serviceCode: '01-DOM-EU', description: 'Point Relais®' },
    { carrier: Carrier.MONDIAL_RL, serviceCode: '01-INT-EU', description: 'home delivery International' },
    { carrier: Carrier.POSTEIT, serviceCode: '01-DOM-EU', description: 'Poste Delivery Express' },
    { carrier: Carrier.POSTEIT, serviceCode: '01-INT-EU', description: 'Poste Delivery International Express' },
    { carrier: Carrier.POSTEIT, serviceCode: '02-DOM-EU', description: 'Poste Delivery Standard' },
    { carrier: Carrier.POSTEIT, serviceCode: '02-INT-EU', description: 'Poste Delivery International Standard' },
    { carrier: Carrier.POSTEIT, serviceCode: '03-DOM-EU', description: 'Poste Delivery Web' },
    { carrier: Carrier.POSTEIT, serviceCode: '03-INT-EU', description: 'Poste Delivery International Web' },
    { carrier: Carrier.POSTEIT, serviceCode: '04-DOM-EU', description: 'Poste Deliverybox Standard' },
    { carrier: Carrier.POSTEIT, serviceCode: '05-DOM-EU', description: 'Poste Deliverybox Express' },
    { carrier: Carrier.POSTEIT, serviceCode: '05-INT-EU', description: 'Poste Deliverybox International Express' },
    { carrier: Carrier.POSTEIT, serviceCode: '06-DOM-EU', description: 'Poste Delivery Europe' },
    { carrier: Carrier.POSTEIT, serviceCode: '06-INT-EU', description: 'Poste Delivery Globe' },
    { carrier: Carrier.PPI, serviceCode: 'HMSC', description: 'HK - Miscellaneous' },
    { carrier: Carrier.PPI, serviceCode: 'HD', description: 'PPI Pickup+Delivery' },
    { carrier: Carrier.PPI, serviceCode: 'JB', description: 'Jewelers Block' },
    { carrier: Carrier.PPI, serviceCode: 'MSC', description: 'Miscellaneous' },
    { carrier: Carrier.PPI, serviceCode: 'RB', description: 'Rebill' },
    { carrier: Carrier.PPI, serviceCode: 'TM', description: 'Tracking and Monitoring' },
    { carrier: Carrier.PPS, serviceCode: 'PPS-01', description: 'PP Secure Next Day' },
    { carrier: Carrier.PUROLATOR, serviceCode: '01-DOM', description: 'Overnight' },
    { carrier: Carrier.PUROLATOR, serviceCode: '02-DOM', description: 'Ground' },
    { carrier: Carrier.ROYALE, serviceCode: 'ROYALE', description: 'ROYALE' },
    { carrier: Carrier.SEAFREIGHT, serviceCode: 'SEAFREIGHT', description: 'SEAFREIGHT' },
    { carrier: Carrier.SF_EXPRESS, serviceCode: '999', description: 'SF Express' },
    { carrier: Carrier.TEI, serviceCode: 'TEI', description: 'TEI' },
    { carrier: Carrier.T_CAT, serviceCode: 'T-CAT', description: 'T-CAT' },
    { carrier: Carrier.TNT, serviceCode: '01-DOM-DE', description: 'Express 8:00' },
    { carrier: Carrier.TNT, serviceCode: '01-INT-DE', description: 'Economy Express' },
    { carrier: Carrier.TNT, serviceCode: '02-DOM-DE', description: 'Express 9:00' },
    { carrier: Carrier.TNT, serviceCode: '02-INT-DE', description: 'Economy Express 12:00' },
    { carrier: Carrier.TNT, serviceCode: '03-DOM-DE', description: 'Express 10:00' },
    { carrier: Carrier.TNT, serviceCode: '03-INT-EU', description: 'Express 10:00 International' },
    { carrier: Carrier.TNT, serviceCode: '04-DOM-DE', description: 'Express 12:00' },
    { carrier: Carrier.TNT, serviceCode: '04-INT-EU', description: 'Express 12:00 International' },
    { carrier: Carrier.TNT, serviceCode: '05-DOM-DE', description: 'Express' },
    { carrier: Carrier.TNT, serviceCode: '05-INT-EU', description: 'Express International' },
    { carrier: Carrier.TNT, serviceCode: '06-DOM-DE', description: 'Express Plus' },
    { carrier: Carrier.TNT, serviceCode: '06-INT-EU', description: 'Express 9:00 International' },
    { carrier: Carrier.TNT, serviceCode: '07-DOM-EU', description: 'Special Services' },
    { carrier: Carrier.TNT, serviceCode: '07-INT-EU', description: 'Special Services International' },
    { carrier: Carrier.TNT, serviceCode: '08-DOM-EU', description: 'Economy Express' },
    { carrier: Carrier.TNT, serviceCode: '09-DOM-EU', description: 'Time Critical Services' },
    { carrier: Carrier.TNT, serviceCode: '09-INT-EU', description: 'Time Critical Services International' },
    { carrier: Carrier.TRUCKERS, serviceCode: 'TF-DOM', description: 'Truck Freight' },
    { carrier: Carrier.UKRM, serviceCode: '02-INT', description: 'STANDARD' },
    { carrier: Carrier.UKRM, serviceCode: '03-INT', description: 'SIGNED FOR' },
    { carrier: Carrier.UKRM, serviceCode: '04-INT', description: 'SPECIAL DELIVERY' },
    { carrier: Carrier.UKRM, serviceCode: 'UK-DOM', description: 'UK-Domestic' },
    { carrier: Carrier.UKRM, serviceCode: 'UK-INT', description: 'UK-International' },
    { carrier: Carrier.UKRM, serviceCode: 'UKRM', description: 'UKRM' },
    { carrier: Carrier.UPS, serviceCode: '01', description: 'Next Day Air' },
    { carrier: Carrier.UPS, serviceCode: '02', description: '2nd Day Air' },
    { carrier: Carrier.UPS, serviceCode: '03', description: 'Ground' },
    { carrier: Carrier.UPS, serviceCode: '06-DOM', description: 'Express Critical' },
    { carrier: Carrier.UPS, serviceCode: '07', description: 'Worldwide Express' },
    { carrier: Carrier.UPS, serviceCode: '08', description: 'Worldwide Expedited' },
    { carrier: Carrier.UPS, serviceCode: '11', description: 'Standard' },
    { carrier: Carrier.UPS, serviceCode: '11-CA-DOM', description: 'Intra Canada Standard' },
    { carrier: Carrier.UPS, serviceCode: '12', description: '3 Day Select' },
    { carrier: Carrier.UPS, serviceCode: '13', description: 'Next Day Air Saver' },
    { carrier: Carrier.UPS, serviceCode: '14', description: 'Next Day Air Early AM' },
    { carrier: Carrier.UPS, serviceCode: '21', description: 'SurePost' },
    { carrier: Carrier.UPS, serviceCode: '54', description: 'Worldwide Express Plus' },
    { carrier: Carrier.UPS, serviceCode: '59', description: '2nd Day Air AM' },
    { carrier: Carrier.UPS, serviceCode: '65', description: 'Worldwide Saver' },
    { carrier: Carrier.UPS, serviceCode: '74', description: 'Express 12:00' },
    { carrier: Carrier.UPS, serviceCode: 'CA-DOM', description: 'Intra-Canada' },
    { carrier: Carrier.UPS, serviceCode: 'EU-DOM', description: 'UE Express' },
    { carrier: Carrier.UPS, serviceCode: 'SP', description: 'UPS SurePost' },
    { carrier: Carrier.UPS, serviceCode: 'UPSS', description: 'UPS Freight' },
    { carrier: Carrier.USPS, serviceCode: 'DOM-01', description: 'Express Mail' },
    { carrier: Carrier.USPS, serviceCode: 'DOM-02', description: 'Priority Mail' },
    { carrier: Carrier.USPS, serviceCode: 'DOM-03', description: 'APO' },
    { carrier: Carrier.USPS, serviceCode: 'DOM-04', description: 'Registered Mail' },
    { carrier: Carrier.USPS, serviceCode: 'INT-01', description: 'Express Mail International' },
    { carrier: Carrier.USPS, serviceCode: 'INT-02', description: 'Priority Mail International' },
    { carrier: Carrier.VCARGO, serviceCode: 'VCARGO', description: 'VCARGO' },
    { carrier: Carrier.YUNDA, serviceCode: 'YUNDA', description: 'YUNDA' },
    { carrier: Carrier.ZTO, serviceCode: 'ZTO', description: 'ZTO' },
];
