/**
 * [dbo].[Carriers]
 */
export enum Carrier {
  AIRLINES = 'AIRLINES',
  APC = 'APC',
  BRINKS = 'BRINKS',
  BRT = 'BRT',
  CANADA = 'CANADA',
  DHL = 'DHL',
  DPD = 'DPD',
  DUNBAR = 'DUNBAR',
  DX = 'DX',
  EMS = 'EMS',
  FEDEX = 'FEDEX',
  FERRARI = 'FERRARI',
  G4SI = 'G4SI',
  GCARGO = 'GCARGO',
  GDEX = 'GDEX',
  GLS = 'GLS',
  LA_POSTE = 'LA-POSTE',
  MALCA = 'MALCA',
  MONDIAL_RL = 'MONDIAL-RL',
  POSTEIT = 'POSTEIT',
  PPI = 'PPI',
  PPS = 'PPS',
  PUROLATOR = 'PUROLATOR',
  ROYALE = 'ROYALE',
  SEAFREIGHT = 'SEAFREIGHT',
  SF_EXPRESS = 'SF EXPRESS',
  T_CAT = 'T-CAT',
  TEI = 'TEI',
  TNT = 'TNT',
  TRUCKERS = 'TRUCKERS',
  UKRM = 'UKRM',
  UPS = 'UPS',
  USPS = 'USPS',
  VCARGO = 'VCARGO',
  YUNDA = 'YUNDA',
  ZTO = 'ZTO'
}
